<template>
    <div class="page">
        <div class="title">节点运行状态</div>
        <div class="tableContainer">
            <el-table :data="tableData" style="width: 100%" height="100%" stripe header-row-class-name="labelClassName">
                <el-table-column label="节点" align="center" #default="{row}">
                    {{row.statusData.nodeName}}
                </el-table-column>
                <el-table-column label="cpu" align="center">
                    <el-table-column #default="{row}" label="核心数" align="center">
                        {{row.statusData.data.cpu.cores}}
                    </el-table-column>
                    <el-table-column #default="{row}" label="频率" align="center">
                        {{row.statusData.data.cpu.speed}}
                    </el-table-column>
                </el-table-column>
                <el-table-column #default="{row}" :render-header="renderHeader" label="内存|使用量/总量" align="center">
                    {{byteToGB(row.statusData.data.mem.total-row.statusData.data.mem.free)}}/{{byteToGB(row.statusData.data.mem.total)}}GB
                </el-table-column>
                <el-table-column #default="{row}" :render-header="renderHeader" label="硬盘|使用量/总量" align="center">
                    <div v-for="(item,index) in row.statusData.data.fsSize" :key="index">
                        {{byteToGB(item.used)}}/{{byteToGB(item.size)}}GB
                    </div>
                </el-table-column>
                <el-table-column #default="{row}" :render-header="renderHeader" label="网络|上行/下行速度" align="center">
                    <div v-for="(item,index) in row.statusData.data.networkStats" :key="index">
                        {{byteToKB(item.tx_sec)}}/{{byteToKB(item.rx_sec)}}KB/S
                    </div>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import axiosCB from '@/axios/indexCB'
    import * as math from 'mathjs'
    export default {
        name: "page2",
        data() {
            return {
                tableData: []
            }
        },
        async mounted() {
            // 1获取所有节点
            let listNodes = (await axiosCB.request({
                url: `/network/listNodes`,
                method: "get",
                params: {
                    networkName: this.$store.state.networkName
                }
            })).data
            let reqList = []
            for (let i = 0; i < listNodes.length; i++) {
                const item = listNodes[i];
                reqList.push(
                    axiosCB.request({
                        url: `/node/${this.$store.state.networkName}/status/${item.nodeName}`
                    })
                )
            }
            let data = await Promise.all(reqList)
            for (let i = 0; i < listNodes.length; i++) {
                const item = listNodes[i];
                item.statusData = data[i].data
            }
            this.tableData = listNodes
        },
        methods: {
            byteToKB(byte) {
                return (byte / 1024).toFixed(2)
            },
            byteToGB(byte) {
                return (byte / 1024 / 1024 / 1024).toFixed(2)
            },
            renderHeader(h, { column, $index }) {
                let labelArry = column.label.split("|")
                return h('div', [h('div', labelArry[0]), h('div', labelArry[1])]);
            }
        }
    }
</script>

<style scoped lang="less">
    .page {
        height: calc(100vh - 128px);
        min-height: 600px;
        display: flex;
        flex-direction: column;
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        padding-top: 32px;
        flex-shrink: 0;
    }

    .tableContainer {
        margin-top: 16px;
        padding: 40px;
        background-color: #FFFFFF;
        width: 100%;
        flex: auto;
        box-sizing: border-box;
        overflow: hidden;
    }

    /deep/ .labelClassName {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
    }
</style>